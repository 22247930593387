<!--
File:  Report_5_RoadwayDetails.vue
Description: produces the report for printing.
-->
<template>
  <md-card>
    <md-card-header class="md-card-header-icon md-card-header-green">
      <div class="md-layout">
        <div class="md-layout-item md-small-size-100 md-size-20">
          <RegionsDropdown class='region-select-dropdown' :label="$t('label.select_region')" v-model="selectedRegion"
            :initial_value="selectedRegion" @input='onRegionChange' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <DepsDropdown class='region-select-dropdown' :label="$t('label.select_deu')" v-model="selectedDeu"
            :initial_value="selectedDeu" :region_id="selectedRegion" @input='onDeuChange' :disabled='!selectedRegion' />
        </div>
        <div class="md-layout-item md-small-size-100 md-size-20">
          <md-datepicker v-model="selectedDate" md-immediately :md-open-on-focus="false">
            <label>{{ $t('label.select_date') }}</label>
          </md-datepicker>
        </div>
        <div class="md-layout-item btn-row md-small-size-100">
          <md-button class="md-success" @click="print" :disabled="btnDisabled"> {{ $t('buttons.print') }}
          </md-button>
        </div>
      </div>
    </md-card-header>

    <md-card-content>
      <md-progress-spinner v-if="showSpinner == true" class="md-progress-spinner" :md-diameter="70"
        md-mode="indeterminate" />
      <md-table class="paginated-table table-striped table-hover" :value="report" :md-sort.sync="currentSort"
        :md-sort-order.sync="currentSortOrder" :md-sort-fn="customSort" md-fixed-header>
        <md-table-empty-state :md-label="$t('label.no_data')" :md-description="$t('messages.select_another_criteria')" />
        <md-table-row slot="md-table-row" slot-scope="{item}">
          <md-table-cell v-if="!selectedRegion" style="max-width: 250px" :md-label="$t('road_network.region')">
            {{ item.region_description }}
          </md-table-cell>
          <md-table-cell v-if="!selectedDeu" :md-label="$t('road_network.dep')">
            {{ $t('road_network.dep') + '-' + item.deu_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.road_description')">
            {{ item.road_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.from_km')" md-numeric>
            {{ item.start_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.to_km')" md-numeric>
            {{ item.end_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('road_network.length')" md-numeric>
            {{ item.end_km - item.start_km | numFormat(3) }}
          </md-table-cell>
          <md-table-cell :md-label="$t('condition.surface_type_desc')">
            {{ item.surface_type_description }}
          </md-table-cell>
          <md-table-cell :md-label="$t('label.width_m')" md-numeric>
            {{ item.width_m | numFormat }}
          </md-table-cell>
          <md-table-cell style="width:20%" :md-label="$t('condition.terrain_type_desc')">
            {{ item.terrain_type_description }}
          </md-table-cell>
        </md-table-row>
      </md-table>
      <div class="md-layout">
        <!--<div>{{ $t('total_regions: ') }}: {{ total_regions }}&nbsp; </div>
            <div>{{ $t('total_deu: ') }}: {{ total_deu }}&nbsp; </div>-->
      </div>
    </md-card-content>
  </md-card>
</template>
<script>
  import RegionsDropdown from '../Dropdowns/RegionsDropdown.vue'
  import DepsDropdown from '../Dropdowns/DepsDropdown.vue'
  import { numFormat } from "@/store/refdata/format_helpers"
  import { customSortMixin } from '@/mixins/customSortMixin'
  import printReport from '@/mixins/printMixin'

  export default {
    mixins: [customSortMixin, printReport],
    name: 'report-road-way-details',
    data() {
      return {
        selectedRegion: null,
        selectedDate: null,
        selectedDeu: null,
        btnDisabled: true,

        showSpinner: false,
        currentSort: 'region_description',
        currentSortOrder: 'asc',
        regionDescr: null,
        deuDescr: null,
        theDate: new Date()
      }
    },
    components: {
      RegionsDropdown,
      DepsDropdown
    },

    created() {
      this.theDate.setHours(0, 0, 0)
      this.selectedDate = this.theDate
    },

    methods: {
      reloadData() {
        this.showSpinner = true
        this.btnDisabled = true
        const report_filters = {
          region_id: this.selectedRegion,
          deu_id: this.selectedDeu,
          selectedDate: this.selectedDate.toISOString().substring(0, 10)
        }
        this.$store.dispatch('REPORT_5_ROADWAY_DETAILS', report_filters).then(() => {
          this.btnDisabled = false
          this.showSpinner = false
        })

      },
      print() {
        this.showSpinner = true
        var prtHtml = "<h4 style='text-align:center'>" + this.$t('label.reports_title') + "</h4>"
        prtHtml += "<h4 style='text-align:center'>" + this.$t('route.report_road_way_details')
        prtHtml += '&nbsp;' + this.$t('label.as_on') + this.selectedDate.toLocaleDateString()

        let titleFilters = ''
        let tableHeaders = '<tr>'
        if (this.selectedRegion) {
          titleFilters += this.$t('label.from_region', { name: this.report[0].region_description })
        } else {
          tableHeaders += `<th>${this.$t('road_network.region')}</th>`
        }
        if (this.selectedDeu) {
          titleFilters += `${this.$t('road_network.dep')}: ${this.report[0].deu_description}`
        } else {
          tableHeaders += `<th>${this.$t('road_network.dep')}</th>`
        }
        if (titleFilters !== '') prtHtml += '&nbsp;(' + titleFilters + ')'
        prtHtml += '</h4>'

        tableHeaders += `<th>${this.$t('road_network.road_key')}</th>
          <th>${this.$t('label.from_km')}</th>
          <th>${this.$t('label.to_km')}</th>
          <th>${this.$t('road_network.length')}</th>
          <th>${this.$t('condition.surface_type_desc')}</th>
          <th>${this.$t('label.width_m')}</th>
          <th>${this.$t('condition.terrain_type_desc')}</th>
        </tr>`

        let tableRows = ''
        this.report.forEach(item => {
          tableRows += '<tr>'
          if (!this.selectedRegion) tableRows += `<td>${item.region_description}</td>`
          if (!this.selectedDeu) tableRows += `<td>${this.$t('road_network.dep') + '-' + item.deu_description}</td>`
          tableRows += `<td>${item.road_description}</td>
            <td class='numCell'>${numFormat(item.start_km, 3)}</td>
            <td class='numCell'>${numFormat(item.end_km, 3)}</td>
            <td class='numCell'>${numFormat(item.end_km-item.start_km, 3)}</td>
            <td>${item.surface_type_description}</td>
            <td class='numCell'>${numFormat(item.width_m)}</td>
            <td>${item.terrain_type_description}</td>
          </tr>`
        })

        this.printReport(prtHtml, tableHeaders, tableRows)
      this.showSpinner = false
      },
      onRegionChange(region_id, regionDescr) {
        this.regionDescr = regionDescr
        this.reloadData()
      },
      onDeuChange(deu_id, deuDescr) {
        this.deuDescr = deuDescr
        this.reloadData()
      },
    },

    computed: {
      report() {
        return this.customSort(this.$store.state.Reports.report_5_data, 'deu_description', 'surface_type_desc')
      },
    },

    watch: {
      selectedDate(newValue, oldValue) {
        if (!newValue) this.selectedDate = this.theDate
        else this.reloadData()
      },
    }
  }
</script>
<style lang="scss" scoped>
.md-card {
  margin: 0px 0;
}

.btn-row {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

::v-deep .md-table-head-label {
  font-size: 14px !important;
  text-align: center !important;
  overflow: visible !important;
  white-space: normal !important;
  padding-right: unset !important;
}

.md-table-cell.md-numeric {
  padding: 0px !important;
  padding-right: 18px !important;
}

.md-progress-spinner {
  margin: 18px;
  position: absolute;
  top: 25%;
  left: 45%;
  z-index: 20;
}
</style>